<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12">
        <v-card class="mx-auto" elevation="8">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="headline mb-1">
                このページの使い方
              </v-list-item-title>

              <v-list-item-subtitle>
                あなたが登録したGoogle Classroomが時間割として表示されます。
              </v-list-item-subtitle>
              <v-card-text v-if="canResearch">
                このページから「授業満足度調査」に回答してください。<br />
                配布された時間割に記載されている授業が全て表示されていることを確認してください。<br />
                未登録の科目があれば、<router-link to="/">授業検索</router-link
                >ページから改めて授業登録をしてください。<br />
                <br />
                授業満足度調査は、より良い授業を実施していくこと、みなさん自身の授業に対する姿勢を振り返ることを目的に実施するものです。
                回答情報は本調査の目的にのみ使用されます。回答が成績評価に影響することはありませんので、必ず全ての問いに漏れなく率直に答えてください。
              </v-card-text>
              <v-btn x-small rounded @click="toggleShow">
                <v-icon>{{
                  show ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
                詳細説明を{{ show ? "閉じる" : "開く" }}
              </v-btn>
              <v-expand-transition>
                <div v-show="show">
                  <v-divider></v-divider>

                  <v-card-text v-if="!canResearch">
                    「登録済み」時間割が表示されます。
                  </v-card-text>
                  <v-card-text v-if="canResearch">
                    キャリアガイダンスで先生の説明を聞いてから24時間以内に回答してください。<br /><br />
                    【授業満足度調査回答方法】
                    <ol>
                      <li>
                        担任の先生から指示された全ての科目について回答してください。念のため配布された時間割も確認し、登録漏れがないか確認してください。
                        <ol class="counterMaru">
                          <li>①各科目の「CS調査回答」ボタンを押します。</li>
                          <li>
                            ②授業内容に対する満足度、教師に対する満足度、あなた自身の努力度について回答して下さい。
                          </li>
                          <li>
                            ③<strong>自由記述欄にも必ず記入して下さい。</strong>特に意見がない場合は「特になし」と書いてください。
                          </li>
                          ※①～③に空欄があると回答が登録されません。
                        </ol>
                      </li>
                      <li>
                        全ての欄に記入をしたら、「回答を送信」ボタンを押してください。<br />
                        ※担任の先生から回答するよう指示された全ての科目について１～２の操作を繰り返し行ってください。<br />
                        ※回答送信後は編集、再送信はできません。送信ボタンを押す前に、内容をよく確認してください。
                      </li>
                    </ol>
                    <div class="additional-message" v-if="canResearch">
                      【注意】
                      <ul>
                        <li>
                          レポート科目等、調査対象外科目がありますので担任の先生に指示に従ってください。
                        </li>
                        <li>
                          ECSなどのように週に複数回ある同じ授業で、曜日により担当の先生が異なる場合、それぞれの先生について回答する必要があります。
                        </li>
                        <li>
                          週に同じ授業がある場合でも、担当の先生が同じであれば、どれか１つに回答すればその授業については回答完了となります。
                        </li>
                        <li>
                          回答が終了したものは「CS調査」のボタンがオレンジ色からグレーに変更されます。オレンジ色のままになっている「CS調査」ないか全て確認してから終了してください。
                        </li>
                      </ul>
                    </div>
                  </v-card-text>
                </div>
              </v-expand-transition>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "Information",
  data: () => ({}),
  computed: {
    show() {
      return this.$store.state.detailShow;
    },
    canResearch() {
      return this.$store.state.canResearch;
    }
  },
  methods: {
    toggleShow() {
      this.$store.commit("TOGGLE_DETAIL_SHOW");
    }
  }
};
</script>
<style scoped>
.info-messages li {
  line-height: 1.5rem;
}
.additional-message {
  padding: 1rem 0 0 0;
}

.counterMaru ol {
  list-style: none;
}
.counterMaru li {
  list-style: none;
}
</style>
